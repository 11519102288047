import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef } from 'react'
import * as Auth from '@aws-amplify/auth';
import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import { Button, Text } from "@aws-amplify/ui-react";
import { downloadFile } from "./lib/common";
import { uploadFile } from "./lib/common";
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';


const GenerateOptionsLegsTraditionalWeekly=({ icType })=> {

  console.log("GenerateOptionsLegsTraditionalWeekly : icType = " + icType);

  let email = useRef();
  let days = useRef();
  const [forceRender, setForceRender]=useState(true);
  let daysOffset =useRef();
  let security = useRef();
  let upperBound = useRef();
  let lowerBound = useRef();
  let profitAlert = useRef();
  let contracts = useRef();
  let position = useRef("Short");
  let minDepth = useRef();
  let defaultIsClicked = useRef(false);
  let optionsLegs = useRef({});
  let shortBounds = useRef({});

  const [settingsStr, setSettingsStr]=useState({"security":"", "profitAlert":"", "contracts":""});
  const [data,setData]=useState(settingsStr);
  const [error,setError]=useState('');
  let [displayMessage, setDisplayMessage] = useState("");
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  const [suggest, setSuggest] = useState(false);
  let [selectedExp, setSelectedExp] = useState(null);
  const daysInputRef = useRef(null);
  const dateInputRef = useRef(null);
  const location = useLocation();
  let passedSecurity = location.state?.security || "";
  let passedSecurityCounter = useRef(0);
  console.log("GenerateOptionsLegsTraditionalWeekly : passedSecurity = " + passedSecurity);

  if (passedSecurity !== "" && data.security === "" && passedSecurityCounter.current ===0) {
    console.log("GenerateOptionsLegsTraditionalWeekly : passedSecurity : data = ", data);
    //passedSecurity = {"security" : passedSecurity};
    passedSecurityCounter.current++;
    security.current = passedSecurity;
    //setData(passedSecurity);
    setData((prevData) => ({
      ...prevData,
      security: passedSecurity
    }));
  }

  let initString="";

  async function listFiles() {
    await console.log("Executing listFiles");
    if (typeof(email.current) === 'undefined') {
      await getUserInfo();
    }
    //setData ( await JSON.parse(await downloadFile(email.current)));
  }

  async function getLegs() {
    console.log("Executing getLegs");
    const myAPI="mktappapi";
    let path = '/getIronCondorOptionsLegsTraditionalWeekly'; 
    console.log("getLegs : data = ", data);
    
    data.profitAlert = data.profitAlert || 0;

    var passApiStr=await path + "/" + data.position.toUpperCase().trim() + "/" + data.security.toUpperCase().trim() + "/" + data.profitAlert + "/" + data.contracts;
    await console.log("getLegs : passApiStr = "+ passApiStr);
    //await Promise.resolve(await get(myAPI, passApiStr))
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response;
    let resp = await response.body.json();
    await Promise.resolve(resp)
      .then(async () =>{
        await console.log("getLegs : resp = " + resp);
        optionsLegs.current = await resp;
        await console.log("getLegs : optionsLegs = " + JSON.stringify(optionsLegs.current));
        setError("");
    })
    .catch(error => {
      setError(error.message);
      console.log("getLegs : Error = " + error.message);
    }) 
  }

  async function getShortBounds() {
    console.log("Executing getShortBounds");
    const myAPI="mktappapi";
    let path = '/getIronCondorShortBounds'; 
    var passApiStr=await path + "/" + data.security.toUpperCase().trim() + "/" + data.days;
    await console.log("getShortBounds : passApiStr = "+ passApiStr);
    //await Promise.resolve(await get(myAPI, passApiStr))
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response;
    let resp = await response.body.json();
    await Promise.resolve(resp)
      .then(async () =>{
        await console.log("getShortBounds : resp = " + resp);
        shortBounds.current = await resp;
        await console.log("getShortBounds : shortBounds = " + JSON.stringify(shortBounds.current));

        upperBound.current = shortBounds.current.upperBound;
        lowerBound.current = shortBounds.current.lowerBound;

        setData(prevData => ({
          ...prevData,
          upperBound: shortBounds.current.upperBound,
          lowerBound: shortBounds.current.lowerBound
        }));

        setError("");
    })
    .catch(error => {
      setError(error.message);
      console.log("getShortBounds : Error = " + error.message);
    }) 
  }

  useEffect(()=>{
    
      console.log ("Executing useEffect");
      if (typeof(email.current) === 'undefined') {
        getUserInfo();
      }
      console.log("useEffect : init = "+JSON.stringify(initString));
      executeSettingsForm();
      
      console.log("useEffect : submitted = " + submitted)
      if (submitted) {
        Promise.resolve(getLegs())
        .then((resp)=>{
          console.log("useEffect : optionsLegs = " + JSON.stringify(optionsLegs.current));

          if (error === "" && Object.keys(optionsLegs.current).length !== 0) {
            navigate("/ironCondorOptionsLegsTraditionalWeekly", { state: { optionsLegs: optionsLegs.current } });
          }
        })
        .catch((error)=>{
          console.log("useEffect : error = ");
          setError(error.message);
          console.log(error);
        })
      }

      if (suggest) {
        Promise.resolve(getShortBounds())
        .then((resp)=>{
          console.log("useEffect : shortBounds = " + JSON.stringify(shortBounds.current));

          if (error === "" && Object.keys(shortBounds.current).length !== 0) {
            setSuggest(false);
            //navigate("/ironCondorOptionsLegs", { state: { optionsLegs: optionsLegs.current } });
          }
        })
        .catch((error)=>{
          console.log("useEffect : error = ");
          setError(error.message);
          console.log(error);
        })
      }
      if (icType === "") {
        handleInputChange();
      }
    //}
  },[forceRender, submitted, settingsStr, data, suggest]);

  const getUserInfo = async () => {
    try {
      await Auth.fetchAuthSession();
      // Retrieve the user's email address
      const user = await Auth.fetchUserAttributes();
      console.log("getUserInfo : email = " + JSON.stringify(user.email));
      email.current = await user.email;
      return "done";
    } catch (error) {
      console.log("Error in getUserInfo: ", error);
      return "error";
    }
  };

  const navigate = useNavigate();
  console.log("Executing GenerateOptionsLegsTraditionalWeekly");

  const executeSettingsForm=async ()=>{
    await getUserInfo().then(()=>{
      console.log("executeSettingsForm : data.days = "+ data.days);
      if (typeof(email.current) === 'undefined') {
        getUserInfo();
      }
      listFiles();
    })
  };

  console.log("main : settingsStr = "+JSON.stringify(settingsStr));
  console.log("main : data = "+JSON.stringify(data));
  console.log("submitted = " + submitted);
  console.log("forceRender = " + forceRender);
  if (defaultIsClicked.current) {
    //setData ({"days":"","daysOffset":"10","minDepth":"10"})
  }

  const handleSuggest = () => {
    setSuggest(true);
  }

  useEffect (()=>{
    console.log("useEffect : icType = " + icType);
    if (icType !== "") {
      days.current = 30;
      security.current = icType;
      upperBound.current = 5;
      lowerBound.current = 0.1;
      profitAlert.current = 0;
      contracts.current = 1;
      position.current = "SHORT";
      if (!submitted) {
        handleSubmit();
      }
    }
  })

  const handleSubmit = ()=>{
    console.log("Executing handleSubmit");

    if (displayMessage === "") {
      setDisplayMessage("Getting IC Data ...");
    }
    
    console.log("handleSubmit : submitted = "+submitted);
    console.log("handleSubmit : security = " + security.current);
    console.log("handleSubmit : profitAlert = " + profitAlert.current);
    console.log("handleSubmit : contracts = " + contracts.current);
    console.log("handleSubmit : position = " + position.current);
    if (!security.current || !contracts.current) {
      setError("Please fill in all fields");
      return;
    }

    setError('');

    setSubmitted(true);
    console.log("handleSubmit : submitted = "+submitted);
    console.log("handleSubmit : security = " + security.current);
    console.log("handleSubmit : profitAlert = " + profitAlert.current);
    console.log("handleSubmit : contracts = " + contracts.current);
    console.log("handleSubmit : position = " + position.current);
  
    
    setData({"security":security.current,"profitAlert":profitAlert.current, "position":position.current, "contracts":contracts.current, "email":email.current});
    console.log("handleSubmit - data = " + JSON.stringify(data));

  }

  function clearValues() {
    console.log("Executing clearValues")
    const clearedData = { days: "", security: "", upperBound: "", lowerBound: "", profitAlert: "", contracts: "" };
    days.current = "";
    security.current = "";
    upperBound.current = "";
    lowerBound.current = "";
    profitAlert.current = "";
    contracts.current = "";
    position.current = "Short";
    console.log("clearValues: Before update, data =", data); 
    
    //setData(clearedData);
    setData({
      ...data,
      days: "",
      security: "",
      upperBound: "",
      lowerBound: "",
      profitAlert: "",
      contracts: "",
      position: "Short" // Reset position in state to 'Short'
    });
    setSettingsStr(clearedData);

    console.log("clearValues: After update, data =", data); 
  }

  const canSuggest = data.position === "Short" && data.security && data.days;

  const handleChangeExpDate = (date) => {
    console.log ("Executing handleChangeExpDate");

    setSelectedExp(date);
    let expDays = countDays(date);
    console.log("handleChangeExpDate : expDays = " + expDays);

    if (date) {
      setData(prevData => ({ ...prevData, days: '' }));
      //setData({ ...data, days: expDays });
      days.current = expDays;
    }
  };

  const handleInputChange = (e) => {
    let name = null;
    let value = null;
    try {
      name = e.target.name;
      value = e.target.value;
    } catch (e) {}
    /*setData(prevData => ({
      ...prevData,
      [name]: value
    }));*/
  
    // Clear the expiration date if days are entered
    if (name === 'days' && value) {
      setSelectedExp(null);
    }
    
  };

  function countDays(expiration) {
    console.log("Executing countDays");

    const today = new Date();
  
    // Ensure all dates are compared at the start of the day for accuracy
    today.setHours(0, 0, 0, 0); 
  
    // Parse the expiration date string into a Date object
    console.log("countDays : received expiration = " + expiration);
    //const expirationDate = new Date(expiration);
    //const localDateTimeString = expiration + 'T00:00:00'; // Assume midnight
    const expirationDate = new Date(expiration);
    expirationDate.setHours(0, 0, 0, 0);

    console.log("countDays : expirationDate = " + expirationDate);
    console.log("countDays : today = " + today);

    console.log("countDays : expirationDate.getTime() = " + expirationDate.getTime());
    console.log("countDays : today.getTime() = " + today.getTime());
  
    const timeDifference = expirationDate.getTime() - today.getTime();
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    
    return dayDifference;
  }

  const isDaysDisabled = selectedExp !== null;
  const isDateDisabled = data.days !== '';

  return (
    <div className="MenuStyle">
      {!error && <div className="blink_me">{displayMessage}</div>}
      {!icType && (
        <>
        {/*(async () => { await getUserInfo()})*/}
        
        {error && <div className="blink_me_red">{error}</div>}
        {/*console.log ("form : days = " + data.days + "\tdaysOffset = " + data.daysOffset + "\tminDepth = " + data.minDepth)*/}
        {/*setData({"days":"","security":"","upperBound":"", "lowerBound":"", "profitAlert":""})*/}
        <form id="settingsForm" name="settingsForm" className="formStyle">
             
          <label className="labelStyle label3"><strong>Security</strong></label>
          <label className="labelStyle label4"><strong>Number Of Contracts</strong></label>
          
          <input
            type="text"
            name="security"
            className="inputStyle input3"
            value={data.security || ''}
            onChange={(e) => {
              setData({ ...data, security: e.target.value });
              security.current = e.target.value;
              setError('');
            }}
          ></input>
          
          <input
            type="text"
            name="contracts"
            className="inputStyle input4"
            value={data.contracts || ''}
            onChange={(e) => {
              setData({ ...data, contracts: e.target.value });
              contracts.current = e.target.value;
              setError('');
            }}
          ></input>
          
          <label className="labelStyle label7"><strong>Profit Alert %</strong></label>
          
          <input
            type="number"
            name="profitAlert"
            className="inputStyle input7"
            value={data.profitAlert || ''}
            onChange={(e) => {
              setData({ ...data, profitAlert: e.target.value });
              profitAlert.current = e.target.value;
              setError('');
            }}
          ></input>
        </form><br></br>

        <div>
          <Button className="buttonStyle" onClick={() => { handleSubmit(); } }>Submit</Button>
          <Button className="buttonStyle" onClick={() => { clearValues(); } }>Clear</Button>
        </div>
          <br></br>
          <textarea hidden={hide} placeholder={error} readOnly></textarea>
        </>
      )}
    </div>
  );
}

export default GenerateOptionsLegsTraditionalWeekly;